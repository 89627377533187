"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var PageHistoryContext_1 = require("./PageHistoryContext");
var PageHistoryReducer_1 = require("./PageHistoryReducer");
var PageHistoryProvider = function (props) {
    var _a = (0, react_1.useReducer)(PageHistoryReducer_1.PageHistoryReducer, PageHistoryContext_1.PageHistoryDefaultState), state = _a[0], dispatch = _a[1];
    return ((0, jsx_runtime_1.jsx)(PageHistoryContext_1.PageHistoryContext.Provider, __assign({ value: {
            pageHistory: state,
            pageHistoryDispatch: dispatch
        } }, { children: props.children })));
};
exports.default = PageHistoryProvider;
