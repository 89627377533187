"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var SquigglyLine = function (_a) {
    var lineColor = _a.lineColor;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "100%", height: "14", overflow: "hidden" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M0,14 L13,0 26,14 39,0 52,14 65,0 78,14 91,0 104,14 117,0 130,14 143,0 156,14 169,0 182,14 195,0 208,14 221,0 234,14 247,0 260,14 273,0 286,14 299,0 312,14 325,0 338,14 351,0 364,14 377,0 390,14 403,0 416,14 429,0 442,14 455,0 468,14 481,0 494,14 507,0 520,14 533,0 546,14 559,0 572,14 585,0 598,14 611,0 624,14 637,0 650,14 663,0 676,14 689,0 702,14 715,0 728,14 741,0 754,14", fill: "none", stroke: lineColor }) })));
};
exports.default = SquigglyLine;
