"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalTheme = void 0;
var colors_1 = require("./colors");
var devices_1 = require("./devices");
var fonts_1 = require("./fonts");
exports.globalTheme = {
    colors: colors_1.colors,
    devices: devices_1.devices,
    fonts: fonts_1.fonts
};
