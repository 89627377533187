"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageHistory = exports.usePageHistoryContext = exports.PageHistoryContext = exports.PageHistoryDefaultState = void 0;
var react_1 = require("react");
var PaymentAmountCard_1 = require("../../components/PaymentAmount/PaymentAmountCard");
var PaymentAmountForm_1 = require("../../components/PaymentAmount/PaymentAmountForm");
var PatientContext_1 = require("../PatientContext/PatientContext");
exports.PageHistoryDefaultState = {
    pageHistory: {
        paymentInformationPage: {
            lastName: "",
            accountNumber: "",
            isPrivacyCheckboxChecked: false
        },
        paymentAmountPage: {
            paymentAmount: {
                amount: 0,
                currency: {
                    code: PatientContext_1.CurrencyCode.USD,
                    symbol: PaymentAmountCard_1.DEFAULT_SYMBOL
                }
            },
            paymentOptions: PaymentAmountForm_1.radioValues.total_balance
        }
    }
};
exports.PageHistoryContext = (0, react_1.createContext)({
    pageHistory: __assign({}, exports.PageHistoryDefaultState),
    pageHistoryDispatch: undefined
});
var usePageHistoryContext = function () {
    return (0, react_1.useContext)(exports.PageHistoryContext);
};
exports.usePageHistoryContext = usePageHistoryContext;
var usePageHistory = function () {
    var pageHistory = (0, react_1.useContext)(exports.PageHistoryContext).pageHistory;
    return pageHistory;
};
exports.usePageHistory = usePageHistory;
