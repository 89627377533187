"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyReducer = exports.CompanyActionType = void 0;
var CompanyActionType;
(function (CompanyActionType) {
    CompanyActionType["setCompanyInformation"] = "setCompanyInformation";
})(CompanyActionType = exports.CompanyActionType || (exports.CompanyActionType = {}));
var CompanyReducer = function (state, action) {
    switch (action.type) {
        case CompanyActionType.setCompanyInformation: {
            return __assign(__assign({}, state), { companyData: action.payload });
        }
    }
};
exports.CompanyReducer = CompanyReducer;
