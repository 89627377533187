"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onlinePatientPaymentsBackendUrl = exports.launchDarklyClientId = exports.pendoApiKey = exports.runtimeEnv = void 0;
var lodash_1 = require("lodash");
// Important: process.env is not a real object, but works off
//  string matching and replacement.
// We cannot extract env vars by name from this object, so
//  we need to have the full process.env.MY_ENV_VAR property access
// spelled out in the default values list.
exports.runtimeEnv = readConfig("runtimeEnv", "NODE_ENV", process.env.NODE_ENV);
exports.pendoApiKey = readConfig("pendoApiKey", "PENDO_API_KEY", process.env.PENDO_API_KEY, "");
exports.launchDarklyClientId = readConfig("launchDarklyClientId", "LAUNCH_DARKLY_CLIENT_ID", process.env.LAUNCH_DARKLY_CLIENT_ID, undefined);
exports.onlinePatientPaymentsBackendUrl = readConfig("onlinePatientPaymentsBackendUrl", "ONLINE_PATIENT_PAYMENTS_BACKEND_URL", process.env.ONLINE_PATIENT_PAYMENTS_BACKEND_URL, "https://".concat(location.hostname, ":8443"));
function readConfig(name, envVar) {
    var defaultValues = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        defaultValues[_i - 2] = arguments[_i];
    }
    //@ts-ignore
    var windowValue = window[name];
    //@ts-ignore
    if (windowValue && window[name] !== envVar && windowValue !== "") {
        return windowValue;
    }
    return (0, lodash_1.head)((0, lodash_1.filter)(defaultValues, function (x) { return x && x !== ""; }));
}
