"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompany = exports.useCompanyContext = exports.CompanyContext = exports.CompanyDefaultState = void 0;
var react_1 = require("react");
exports.CompanyDefaultState = {
    companyData: {}
};
exports.CompanyContext = (0, react_1.createContext)({
    companyState: exports.CompanyDefaultState,
    companyDispatch: undefined
});
var useCompanyContext = function () {
    return (0, react_1.useContext)(exports.CompanyContext);
};
exports.useCompanyContext = useCompanyContext;
var useCompany = function () {
    var companyState = (0, react_1.useContext)(exports.CompanyContext).companyState;
    return companyState;
};
exports.useCompany = useCompany;
