"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterNotAllowedCharacters = exports.validateNumbers = void 0;
var validateNumbers = function (value) {
    return /^\d+$/.test(value);
};
exports.validateNumbers = validateNumbers;
var filterNotAllowedCharacters = function (value) {
    return value.replace(/[^0-9]/g, "");
};
exports.filterNotAllowedCharacters = filterNotAllowedCharacters;
