"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLayout = exports.useLayoutContext = exports.LayoutContext = exports.LayoutDefaultState = void 0;
var react_1 = require("react");
exports.LayoutDefaultState = {
    isMobile: false
};
exports.LayoutContext = (0, react_1.createContext)({
    layoutState: exports.LayoutDefaultState,
    layoutDispatch: undefined
});
var useLayoutContext = function () {
    return (0, react_1.useContext)(exports.LayoutContext);
};
exports.useLayoutContext = useLayoutContext;
var useLayout = function () {
    var layoutState = (0, react_1.useContext)(exports.LayoutContext).layoutState;
    return layoutState;
};
exports.useLayout = useLayout;
