"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsCompanyDataEmpty = void 0;
var react_1 = require("react");
var CompanyContext_1 = require("../../context/companyContext/CompanyContext");
var lodash_1 = require("lodash");
var useIsCompanyDataEmpty = function () {
    var companyData = (0, CompanyContext_1.useCompany)().companyData;
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(companyData)) {
            return true;
        }
        return false;
    }, [companyData]);
};
exports.useIsCompanyDataEmpty = useIsCompanyDataEmpty;
