"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SynchronousUseRestoreData = exports.useRestoreData = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var PatientReducer_1 = require("../../context/PatientContext/PatientReducer");
var CompanyReducer_1 = require("../../context/companyContext/CompanyReducer");
var runtime_config_1 = require("../../runtime-config");
var react_router_dom_1 = require("react-router-dom");
var useCheckDataExist_1 = require("./useCheckDataExist");
var useIsPatientDataEmpty_1 = require("./useIsPatientDataEmpty");
var useIsCompanyDataEmpty_1 = require("./useIsCompanyDataEmpty");
var PatientContext_1 = require("../../context/PatientContext/PatientContext");
var CompanyContext_1 = require("../../context/companyContext/CompanyContext");
var CompanyApi_1 = require("../../services/CompanyApi");
var PatientApi_1 = require("../../services/PatientApi");
var PatientInformationCard_1 = require("../PatientInformation/PatientInformationCard");
var ReceiptApi_1 = require("../../services/ReceiptApi");
var useRestoreData = function () {
    var params = (0, react_router_dom_1.useParams)();
    var companyHash = params.companyHash;
    var transactionId = params.transactionId;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var isCompanyDataEmpty = (0, useIsCompanyDataEmpty_1.useIsCompanyDataEmpty)();
    var isPatientDataEmpty = (0, useIsPatientDataEmpty_1.useIsPatientDataEmpty)();
    var patientDispatch = (0, PatientContext_1.usePatientContext)().patientDispatch;
    var companyDispatch = (0, CompanyContext_1.useCompanyContext)().companyDispatch;
    (0, react_1.useEffect)(function () {
        if (isCompanyDataEmpty) {
            handleCompanyDataFetch(companyHash, companyDispatch, navigate);
        }
        if (isPatientDataEmpty) {
            handleJWTDataFetchReceiptPage(companyHash, transactionId, navigate, patientDispatch);
        }
    }, [
        companyHash,
        transactionId,
        isCompanyDataEmpty,
        isPatientDataEmpty,
        navigate,
        patientDispatch,
        companyDispatch
    ]);
};
exports.useRestoreData = useRestoreData;
var SynchronousUseRestoreData = function (Component, props) {
    var params = (0, react_router_dom_1.useParams)();
    var companyHash = params.companyHash;
    var _a = (0, react_1.useState)(false), isLoading = _a[0], setIsLoading = _a[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var isPatientDataEmpty = (0, useIsPatientDataEmpty_1.useIsPatientDataEmpty)();
    var isCompanyDataEmpty = (0, useIsCompanyDataEmpty_1.useIsCompanyDataEmpty)();
    var patientDispatch = (0, PatientContext_1.usePatientContext)().patientDispatch;
    var companyDispatch = (0, CompanyContext_1.useCompanyContext)().companyDispatch;
    var isDataEmpty = (0, useCheckDataExist_1.useCheckDataExist)();
    (0, react_1.useEffect)(function () {
        if (!isLoading) {
            setIsLoading(true);
            if (isCompanyDataEmpty) {
                handleCompanyDataFetch(companyHash, companyDispatch, navigate);
            }
            if (isPatientDataEmpty) {
                handleJWTDataFetchPaymentAmountPage(companyHash, navigate, patientDispatch);
            }
        }
    }, [
        companyHash,
        isLoading,
        isCompanyDataEmpty,
        isPatientDataEmpty,
        companyDispatch,
        patientDispatch,
        setIsLoading,
        navigate
    ]);
    return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [" ", !isDataEmpty &&
                !isCompanyDataEmpty &&
                !isPatientDataEmpty &&
                (0, jsx_runtime_1.jsx)(Component, __assign({}, props)), " "] });
};
exports.SynchronousUseRestoreData = SynchronousUseRestoreData;
var handleCompanyDataFetch = function (companyHash, companyDispatch, navigate) {
    var fetchCompanyData = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!companyHash) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, CompanyApi_1.getCompany)(companyHash)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: throw "Company Hash is not defined";
            }
        });
    }); };
    fetchCompanyData()
        .then(function (companyData) {
        if (!companyData.hasError) {
            companyDispatch({
                type: CompanyReducer_1.CompanyActionType.setCompanyInformation,
                payload: companyData
            });
        }
        else {
            navigate("/".concat(companyHash));
        }
    }).catch(function () {
        console.log("Invalid Session");
        navigate("/".concat(companyHash));
    });
};
var handleJWTDataFetchPaymentAmountPage = function (companyHash, navigate, patientDispatch) {
    fetchRestoreData()
        .then(function (patientData) {
        if (patientData) {
            // At this point, we've refreshed, so no state exists anywhere.
            // This will initialize the state we need if we have it on the JWT
            patientDispatch({
                type: PatientReducer_1.PatientActionType.setPatientInformation,
                payload: {
                    total_patient_balance: patientData.paymentAmount,
                    lastName: patientData.lastName.charAt(0).toUpperCase() +
                        patientData.lastName.slice(1),
                    accountNumber: patientData.accountNumber.toUpperCase(),
                    patientId: patientData.patientId,
                }
            });
        }
        else {
            navigate("/".concat(companyHash));
        }
        return fetchRecentPayments(patientData);
    })
        .then(function (recentPayments) {
        if (recentPayments) {
            patientDispatch({
                type: PatientReducer_1.PatientActionType.setRecentPaymentsInformation,
                payload: recentPayments
            });
        }
    })
        .catch(function () {
        console.log("Invalid Session");
        navigate("/".concat(companyHash));
    })
        .finally(function () {
        /** Do something here if needed */
    });
};
var handleJWTDataFetchReceiptPage = function (companyHash, transactionId, navigate, patientDispatch) {
    fetchRestoreData()
        .then(function (patientData) {
        if (patientData) {
            // At this point, we've refreshed, so no state exists anywhere.
            // This will initialize the state we need if we have it on the JWT
            patientDispatch({
                type: PatientReducer_1.PatientActionType.setPatientInformation,
                payload: {
                    total_patient_balance: patientData.paymentAmount,
                    lastName: patientData.lastName.charAt(0).toUpperCase() +
                        patientData.lastName.slice(1),
                    accountNumber: patientData.accountNumber.toUpperCase(),
                    patientId: patientData.patientId
                }
            });
        }
        else {
            navigate("/".concat(companyHash));
        }
        return fetchTransactionData(transactionId);
    })
        .then(function (transaction) {
        if (transaction) {
            patientDispatch({
                type: PatientReducer_1.PatientActionType.setPaymentInformation,
                payload: {
                    amount: 0,
                    currency: { code: PatientContext_1.CurrencyCode.USD, symbol: "$" },
                    internalTransactionId: transaction.internalTransactionId,
                    worldPayPaymentData: {
                        approvedAmount: transaction.approvedAmount,
                        cardLogo: transaction.cardLogo,
                        lastFour: transaction.lastFour,
                        status: transaction.status,
                        tranDate: transaction.tranDate,
                        transactionId: transaction.transactionId
                    }
                }
            });
        }
        else {
            navigate("/".concat(companyHash));
        }
    })
        .catch(function () {
        console.log("Invalid Session");
        navigate("/".concat(companyHash));
    })
        .finally(function () {
        /** Do something here if needed */
    });
};
function fetchRestoreData() {
    return __awaiter(this, void 0, void 0, function () {
        var request, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        mode: "cors",
                        credentials: "include"
                    };
                    return [4 /*yield*/, fetch("".concat(runtime_config_1.onlinePatientPaymentsBackendUrl, "/session/restore"), request)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function fetchTransactionData(transactionId) {
    return __awaiter(this, void 0, void 0, function () {
        var res, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, ReceiptApi_1.getTransactionData)(transactionId)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function fetchRecentPayments(patientData) {
    return __awaiter(this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, PatientApi_1.getRecentPayments)(patientData.patientId, PatientInformationCard_1.TIME_INTERVAL)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_2 = _a.sent();
                    console.log(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
;
