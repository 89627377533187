"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePatient = exports.usePatientContext = exports.PatientContext = exports.PatientDefaultState = exports.CurrencyCode = void 0;
var react_1 = require("react");
var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["USD"] = "USD";
})(CurrencyCode = exports.CurrencyCode || (exports.CurrencyCode = {}));
exports.PatientDefaultState = {
    patientData: {}
};
exports.PatientContext = (0, react_1.createContext)({
    patientState: exports.PatientDefaultState,
    patientDispatch: undefined
});
var usePatientContext = function () {
    return (0, react_1.useContext)(exports.PatientContext);
};
exports.usePatientContext = usePatientContext;
var usePatient = function () {
    var patientState = (0, react_1.useContext)(exports.PatientContext).patientState;
    return patientState;
};
exports.usePatient = usePatient;
