"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pathname = exports.appPaths = void 0;
var appPaths;
(function (appPaths) {
    appPaths["PATIENT_INFORMATION"] = "patient-information";
    appPaths["PAYMENT_AMOUNT"] = "payment-amount";
    appPaths["PAYMENT_INFORMATION"] = "payment-information";
    appPaths["RECEIPT"] = "receipt";
})(appPaths = exports.appPaths || (exports.appPaths = {}));
var pathBreadcrumbs = function (companyId) {
    var _a;
    return (_a = {},
        _a[appPaths.PATIENT_INFORMATION] = {
            next: "/".concat(companyId, "/payment-amount")
        },
        _a[appPaths.PAYMENT_AMOUNT] = {
            back: "/".concat(companyId),
            next: "/".concat(companyId, "/payment-information")
        },
        _a[appPaths.PAYMENT_INFORMATION] = {
            back: "/".concat(companyId, "/payment-amount"),
            next: "/".concat(companyId, "/receipt/:id")
        },
        _a[appPaths.RECEIPT] = {
            back: "/".concat(companyId, "/payment-amount")
        },
        _a);
};
var pathname = function (companyId, pathname) {
    var paths = pathBreadcrumbs(companyId);
    return paths[pathname];
};
exports.pathname = pathname;
