"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    backgroundGrey: "#FBFBFB",
    lightBlue: "#E9F1F4",
    grey: "#8494A6",
    linkBlue: "#1361E0",
    textColor: "#354052",
    boxShadow: "5px 5px 10px #0000002c"
};
