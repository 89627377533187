"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsPatientDataEmpty = void 0;
var react_1 = require("react");
var PatientContext_1 = require("../../context/PatientContext/PatientContext");
var lodash_1 = require("lodash");
var useIsPatientDataEmpty = function () {
    var patientData = (0, PatientContext_1.usePatient)().patientData;
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(patientData)) {
            return true;
        }
        return false;
    }, [patientData]);
};
exports.useIsPatientDataEmpty = useIsPatientDataEmpty;
