"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.devices = exports.size = void 0;
exports.size = {
    mobile: "600px",
    tablet: "917px"
};
exports.devices = {
    mobile: "(max-width: ".concat(exports.size.mobile, ")"),
    tablet: "(max-width: ".concat(exports.size.tablet, ")")
};
