"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageHistoryReducer = exports.PageHistoryActionType = void 0;
var PageHistoryContext_1 = require("./PageHistoryContext");
var PageHistoryActionType;
(function (PageHistoryActionType) {
    PageHistoryActionType["setPaymentAmountHistory"] = "setPaymentAmountHistory";
    PageHistoryActionType["setPaymentInformationHistory"] = "setPaymentInformationHistory";
    PageHistoryActionType["clearPageHistory"] = "clearPageHistory";
})(PageHistoryActionType = exports.PageHistoryActionType || (exports.PageHistoryActionType = {}));
var PageHistoryReducer = function (state, action) {
    switch (action.type) {
        case PageHistoryActionType.setPaymentAmountHistory: {
            return __assign(__assign({}, state), { pageHistory: __assign(__assign({}, state.pageHistory), { paymentAmountPage: action.payload }) });
        }
        case PageHistoryActionType.setPaymentInformationHistory: {
            return __assign(__assign({}, state), { pageHistory: __assign(__assign({}, state.pageHistory), { paymentInformationPage: action.payload }) });
        }
        case PageHistoryActionType.clearPageHistory: {
            return __assign(__assign({}, state), PageHistoryContext_1.PageHistoryDefaultState);
        }
    }
};
exports.PageHistoryReducer = PageHistoryReducer;
