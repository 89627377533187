"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LayoutEvents = void 0;
var LayoutReducer_1 = require("./LayoutReducer");
var LayoutEvents = /** @class */ (function () {
    function LayoutEvents() {
    }
    LayoutEvents.setIsMobile = function (dispatch, payload) {
        dispatch({
            type: LayoutReducer_1.LayoutActionType.setIsMobile,
            payload: payload
        });
    };
    return LayoutEvents;
}());
exports.LayoutEvents = LayoutEvents;
