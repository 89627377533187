"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberFieldValidators = exports.lastNameValidators = exports.filterOnlyAllowedCharacters = exports.isAccountNumberBetweenLengthAllowed = exports.isLastNameBetweenLengthAllowed = exports.hasLastNameAllowedCharactersOnly = exports.hasNotAllowedCharacterAccountNumber = exports.hasAtLeastOneNumberAndLetter = exports.REGEX_LAST_NAME_VALIDATOR = exports.MIN_NUMBER_OF_CHARACTERS = exports.MAX_NUMBER_OF_CHARACTERS = void 0;
exports.MAX_NUMBER_OF_CHARACTERS = 64;
exports.MIN_NUMBER_OF_CHARACTERS = 2;
exports.REGEX_LAST_NAME_VALIDATOR = "0-9!@#$%^&*()_+={}[\\]|\\\\/:;<>?";
var hasAtLeastOneNumberAndLetter = function (value) {
    return /^(?=.*[0-9])(?=.*[a-zA-Z])/.test(value);
};
exports.hasAtLeastOneNumberAndLetter = hasAtLeastOneNumberAndLetter;
var hasNotAllowedCharacterAccountNumber = function (value) {
    return !/[^a-zA-Z0-9 ]/.test(value);
};
exports.hasNotAllowedCharacterAccountNumber = hasNotAllowedCharacterAccountNumber;
var hasLastNameAllowedCharactersOnly = function (str) {
    var regex = new RegExp("^[^".concat(exports.REGEX_LAST_NAME_VALIDATOR, "]+$"));
    return regex.test(str);
};
exports.hasLastNameAllowedCharactersOnly = hasLastNameAllowedCharactersOnly;
function isLastNameBetweenLengthAllowed(str, min, max) {
    return str.length >= min && str.length <= max;
}
exports.isLastNameBetweenLengthAllowed = isLastNameBetweenLengthAllowed;
function isAccountNumberBetweenLengthAllowed(str) {
    return str.length <= exports.MAX_NUMBER_OF_CHARACTERS;
}
exports.isAccountNumberBetweenLengthAllowed = isAccountNumberBetweenLengthAllowed;
var filterOnlyAllowedCharacters = function (str) {
    var regex = new RegExp("[".concat(exports.REGEX_LAST_NAME_VALIDATOR, "]"), "g");
    return str.replace(regex, "");
};
exports.filterOnlyAllowedCharacters = filterOnlyAllowedCharacters;
var lastNameValidators = function (value) {
    if ((0, exports.hasLastNameAllowedCharactersOnly)(value) &&
        isLastNameBetweenLengthAllowed(value, exports.MIN_NUMBER_OF_CHARACTERS, exports.MAX_NUMBER_OF_CHARACTERS)) {
        return false;
    }
    return true;
};
exports.lastNameValidators = lastNameValidators;
var numberFieldValidators = function (accountNumberField) {
    if ((0, exports.hasNotAllowedCharacterAccountNumber)(accountNumberField) &&
        accountNumberField.length > 0) {
        return false;
    }
    return true;
};
exports.numberFieldValidators = numberFieldValidators;
