"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckDataExist = void 0;
var react_1 = require("react");
var PatientContext_1 = require("../../context/PatientContext/PatientContext");
var CompanyContext_1 = require("../../context/companyContext/CompanyContext");
var lodash_1 = require("lodash");
var useCheckDataExist = function () {
    var patientData = (0, PatientContext_1.usePatient)().patientData;
    var companyData = (0, CompanyContext_1.useCompany)().companyData;
    return (0, react_1.useMemo)(function () {
        if ((0, lodash_1.isEmpty)(companyData) && (0, lodash_1.isEmpty)(patientData)) {
            return true;
        }
        return false;
    }, [patientData, companyData]);
};
exports.useCheckDataExist = useCheckDataExist;
